import {
    LEVANTAR_AUTORATING
} from "../types/types";

const initialState = {
    cargando: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LEVANTAR_AUTORATING:
            return {
                ...state,
                cargando: action.payload,
            };
        default:
            return state;
    }
}


export function EjecutarAutoRating(value) {
    return (dispatch) => {
      dispatch(Ejecutarme(value));
    };
  }
  
  export const Ejecutarme = (value) => ({
    type: LEVANTAR_AUTORATING,
    payload: value,
  });
  