import {
  INICIO_FORM,
  EXITO_FORM,
  ERROR_FORM,
  EXITO_FORM_TIENDA_MEGAX1,
  ERROR_FORM_TIENDA_MEGAX1,
  CARGANDO_FORMULARIO_EMPRENDER
} from "../types/types";
import clienteAxios from "../config/axios";
import { toast } from "react-toastify"
const initialState = {
  loading: false,
  Mensaje: "",
  enviado: false,
  error: false,
  mensajeTiendita:'',
  cargandoFormulario:false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INICIO_FORM: {
      return {
        ...state,
        loading: action.payload,
        enviado: false,
      };
    }

    case EXITO_FORM:
      return {
        ...state,
        loading: false,
        Mensaje: action.payload,
        enviado: true,
      };
    case ERROR_FORM:
      return {
        ...state,
        error: true,
      };
    case EXITO_FORM_TIENDA_MEGAX1:
      return {
        ...state,
        mensajeTiendita:action.payload
      }
    case CARGANDO_FORMULARIO_EMPRENDER:
      return{
        ...state,
        cargandoFormulario:action.payload
      }  
    default:
      return state;
  }
}

export function EnviarDataSolicitud(Form) {  
  return (dispatch) => {
    clienteAxios
      .post(`mywsmobil/api/SolicitudUsuarioWeb/SolicitarInfo`, Form)
      .then((Respuesta) => {
        if (Respuesta.data.Resultado) {
          dispatch(EnviadoExito(Respuesta.data.Mensaje));
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };
}

export function EnviarDataTiendaMegax(data, checked, checked2, Anexos,Aux_recaptcha1) {
  const usuario = {
    EntityID: "",
    IdDivision:17,
    Nombres: data.Nombres,
    Apellidos: data.Apellidos,
    Direccion: data.Direccion,
    TipoCliente: data.TipoCliente,
    Telefono: data.Telefono,
    Correo: data.Correo,
    Departamento: data.Departamento,
    Municipio: data.Municipio,
    Zona: data.Zona,
    AceptoTerminos: (data.AceptoTerminos = checked),
    AceptoPoliticas: (data.AceptoPoliticas = checked2),
    Tipo: "Solicitud",
    Anexos: Anexos,
    Aux_recaptcha:Aux_recaptcha1,
    RecaptchaName:"reCaptchaPublicKeyMiTienditaMegax"
  };
  return (dispatch) => {
    clienteAxios
      .post(`mywsmobil/api/SolicitudUsuarioWeb/SolicitarInfo`, usuario)
      .then((Respuesta) => {
        if (Respuesta.data.Resultado) {
          dispatch(EnviadoExito1(Respuesta.data.Mensaje));
          toast.success(Respuesta.data.Mensaje)
        }else{
          dispatch(EnviadoExito1(Respuesta.data.Mensaje));
          toast.warn(Respuesta.data.Mensaje)
        }
        dispatch(CargandoFormularioEmprender(false));

      })
      .catch((error) => {
        dispatch(EnviarError1(error));
        dispatch(CargandoFormularioEmprender(false));
      });
  };
}

export function LevantarBackdrow(value) {
  return (dispatch) => {
    dispatch(EnviarInicio(value));
  };
}
export const EnviarInicio = (data) => ({
  type: INICIO_FORM,
  payload: data,
});

export const EnviadoExito = (data) => ({
  type: EXITO_FORM,
  payload: data,
});
export const EnviadoExito1 = (data) => ({
  type: EXITO_FORM_TIENDA_MEGAX1,
  payload: data,
});

export const EnviarError1 = (data) => ({
  type: ERROR_FORM_TIENDA_MEGAX1,
  payload: data,
});
export const CargandoFormularioEmprender = (data) => ({
  type: CARGANDO_FORMULARIO_EMPRENDER,
  payload: data,
});


