import {
  ENVIAR_PRODUCTO_INICIO,
  ENVIAR_PRODUCTO_EXITO,
  ENVIAR_PRODUCTO_ERROR,
  BOTON_DIALOG_ENVIAR,
  VALIDANDO_fORMULARIO,
  BOTON_LEVANTAR_PARA_ASOCIAR,
  BOTON_LEVANTAR_PARA_ASOCIAR_BACKDROP,
  AGREGAR_PRODUCTO_EXITO,
  GUARDAR_PRECIO,
  GUARDAR_TOTAL_SUBTOTAL
} from "../types/types";
import clienteAxios from "../config/axios";
import Swal from "sweetalert2";


const initialState = {
  formulario: [],
  cargando: null,
  error: null,
  enviado: null,
  buttonDialog: false,
  buttonDialogEnviar: false,
  stepp: 0,
  cargandoAsociar: false,
  cargandoAsociar_Backdrop: false,
  precioMaximo: false,
  subtotal: 0.0,
  total: 0.0,
  envio: 0,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case ENVIAR_PRODUCTO_INICIO:
      return {
        ...state,
        formulario: [],
        cargando: true,
        error: null,
        enviado: null
      };
    case ENVIAR_PRODUCTO_EXITO:
      return {
        ...state,
        formulario: action.payload,
        enviado: true,
        cargando: false,
        error: null,
      };
    case ENVIAR_PRODUCTO_ERROR:
      return {
        ...state,
        formulario: [],
        cargando: null,
        error: true,
        enviado: null,
      };
    case BOTON_DIALOG_ENVIAR:
      return {
        ...state,
        cargando: false,
        buttonDialog: action.payload,
        stepp: 3,
      };

    case VALIDANDO_fORMULARIO:
      return {
        ...state,
        cargando: false,
        enviado: null,
        formulario: []
      };

    case BOTON_LEVANTAR_PARA_ASOCIAR:
      return {
        ...state,
        cargandoAsociar: action.payload,
      };

    case BOTON_LEVANTAR_PARA_ASOCIAR_BACKDROP:
      return {
        ...state,
        cargandoAsociar_Backdrop: action.payload,
      };
    case GUARDAR_PRECIO: {
      return {
        ...state,
        precioMaximo: action.payload
      }
    }
    case GUARDAR_TOTAL_SUBTOTAL: {
      return {
        ...state,
        subtotal: action.subtotal,
        total: action.total,
        envio: action.envio
      }
    }

    default:
      return state;
  }
}

export function enviarDataActions(data) {
  return (dispatch) => {
    dispatch(enviarDataInicio());

    clienteAxios
      .post(`MyWsOneVenta/api/doc/SolicitarCotizador/`, data)

      .then((respuesta) => {
        if (respuesta.data.Resultado) {
          dispatch(enviarDataExito(respuesta.data));
          localStorage.removeItem("carrito");

        } else {
          dispatch(enviarDataError(true));
          Swal.fire("Exepcion", `${respuesta.data.Mensaje}`, "warning");
          dispatch(LevantarDialog(false));
        }
      })
      .catch((error) => {
        dispatch(enviarDataError(error));
        dispatch(LevantarDialog(false));
        Swal.fire("Exepcion", `${error}`, "warning");
      });
  };
}

export const enviarDataInicio = () => ({
  type: ENVIAR_PRODUCTO_INICIO,
});

export const enviarDataExito = (datos) => ({
  type: ENVIAR_PRODUCTO_EXITO,
  payload: datos,
});

export const enviarDataError = (error) => ({
  type: ENVIAR_PRODUCTO_ERROR,
  payload: error,
});
export function LevantarDialogActions(value) {
  return (dispatch) => {
    dispatch(LevantarDialog(value));
  };
}
export const LevantarDialog = (value) => ({
  type: BOTON_DIALOG_ENVIAR,
  payload: value,
});

export function ColocarEnfalsoEnviado() {
  return (dispatch) => {
    dispatch(ColocarEnfalso());
    dispatch(LevantarDialog(false));
  };
}

export const ColocarEnfalso = () => ({
  type: VALIDANDO_fORMULARIO,
});

export function enviarPAraAsociarActions(data) {
  return (dispatch) => {
    clienteAxios
      .post(`MyWsOneVenta//FocalizarCatalogoDisdelsa`, data)
      .then((respuesta) => {
        console.log(respuesta.data);
        if (respuesta.data.Resultado) {
          Swal.fire("", `${respuesta.data.Mensaje}`, "success");
          localStorage.removeItem("carrito");
          dispatch(LoadingAsociarActions(false));
          dispatch(LevantarDialogASociar(false));
          dispatch(agregarCarritoExitoBackdr());
        } else {
          dispatch(enviarDataError(true));
          Swal.fire("Exepcion", `${respuesta.data.Mensaje}`, "warning");
          dispatch(LoadingAsociarActions(false));
        }
      })
      .catch((error) => {
        //  dispatch(enviarDataError(error));
        dispatch(LoadingAsociarActions(false));
        Swal.fire("Exepcion", `${error}`, "warning");
      });
  };
}

export function LevantarDialogActionsASociar(value) {
  return (dispatch) => {
    dispatch(LevantarDialogASociar(value));
  };
}
export const LevantarDialogASociar = (value) => ({
  type: BOTON_LEVANTAR_PARA_ASOCIAR,
  payload: value,
});

export function LevantarLoadingAsociarActions(value) {
  return (dispatch) => {
    dispatch(LoadingAsociarActions(value));
  };
}
export const LoadingAsociarActions = (value) => ({
  type: BOTON_LEVANTAR_PARA_ASOCIAR_BACKDROP,
  payload: value,
});

export const agregarCarritoExitoBackdr = () => ({
  type: AGREGAR_PRODUCTO_EXITO,
  payload: [],
});

export function GuardarCantidadmayor(value) {
  return (dispatch) => {
    dispatch(guardarPrecio(value));
  };
}
export const guardarPrecio = (value) => ({
  type: GUARDAR_PRECIO,
  payload: value,
});

export function CreacionPedido(Subtotal, Total, Encabezado, Detalle, UUIDTocken, PrecioEnvio,recaptchaValue) {
  console.log(Encabezado)
  let newArrayDetalle = [];
  Detalle.forEach(element => {
    const NuevosDatos = {
      Cantidad: 0,
      CodProducto: "",
      PrecioIVA: 0.0,
      BaseDefault: "",
      Descripcion: "",
      Imagen: "",
      Descuento: 0.0
    }
    NuevosDatos.Cantidad = element.Cantidad;
    NuevosDatos.CodProducto = element.CodProducto;
    NuevosDatos.PrecioIVA = element.PrecioIVA;
    NuevosDatos.BaseDefault = element.BaseDefault;
    NuevosDatos.Descripcion = element.Descripcion;
    NuevosDatos.Imagen = element.Imagen;
    NuevosDatos.Empaque = element.Base === "Y" ? element.Unidad : element.Fardo
    newArrayDetalle.push(NuevosDatos);
  });
  if (PrecioEnvio != 0) {
    let ProductoDescuento = {
      "Cantidad": 1,
      "CodProducto": "148",
      "PrecioIVA": PrecioEnvio,
      "BaseDefault": "Y",
      "Descripcion": "costo del evio producto",
      "Descuento": 0.0,
      "Proveedor": "Compras",
      "Imagen": "PTQ4117-imgSap-09082019132945.jpg",
      "PrecioBase": 0.01,
      "Empaque": ""
    }
    newArrayDetalle.push(ProductoDescuento);
  }
  const NuevosDatosEncabezado = {
    EntityID: 0,
    Correo: Encabezado.correo,
    Contacto: "",
    AuxDepartamento: Encabezado.departamento,
    AuxMunicipio: Encabezado.municipio,
    AuxDireccion: Encabezado.direccion,
    AuxTelefono: Encabezado.telefono,
    AuxComentario: Encabezado.comentario,
    TipoDoc: Encabezado.TipoDoc,
    DireccionFisicaEntrega: "",
    Comentario: "Email:" + Encabezado.correo + " Tel: " + Encabezado.telefono + " , " + " Depto:  " + Encabezado.departamento + " , Mun : "
      + Encabezado.municipio + " , " +
      "Metodo Pago :" + Encabezado.metodopago + " , Comentario :" + Encabezado.comentario,
    Empresa: Encabezado.empresa,
    TipoCliente: Encabezado.metodopago,
    PreguntarPor: Encabezado.nombre,
    AuxNombreFiscal:Encabezado.nombrenit,
    Almacen: "03",
    U_TipoEntrega: "E",
    DefinidiosUsuario: {
      TipoEntrega: "E",
      DocNIT: Encabezado.u_DoctoNIT,
    },
    SubTotal: Subtotal,
    TotalDocumento: Total,
    Autor: "Mi Tiendita Megax",
    UUID: UUIDTocken,
    AuxprecioEnvio: PrecioEnvio,
    AuxDireccionFiscal: Encabezado.DireccionFactura,
    Recaptcha_key:recaptchaValue,
    PaginaProvenienteRecaptcha:"reCaptchaPublicKeyMiTienditaMegax"
  }
  const Data = {
    Encabezado: NuevosDatosEncabezado,
    Detalle: newArrayDetalle,
  };
  return (dispatch) => clienteAxios
    .post(`MyWsOneVenta/CreacionPedidoMegax`, Data)
    .then((respuesta) => {
      if (respuesta.data.Resultado) {
        dispatch(enviarDataExito(respuesta.data));
        localStorage.removeItem("carrito");
        localStorage.removeItem("Tocken");
        Swal.fire("", "Pedido Creado exitosamente ", "success");
      } else {
        Swal.fire("", `${respuesta.data.Mensaje}`, "warning");
        dispatch(LevantarDialog(false));
      }
    })
    .catch((error) => {
      dispatch(LevantarDialog(false));
      Swal.fire("Exepcion", `${error}`, "warning");
    });
}

export function GuardarJsonTocken(UV4) {
  const user = { NombreUsuario: "" }
  user.NombreUsuario = UV4;
  debugger;
  return () => {
    clienteAxios.
      post(`MyWsOneControlCenter/GenerarJWTockenMiTienditaMegax`, user)
      .then((respuesta) => {
        if (respuesta.data.Resultado) {
          localStorage.setItem("Tocken", respuesta.data.Mensaje);
        } else {
          localStorage.setItem("Tocken", "");
        }

      }).catch((error => {
        console.log(error)
      }))
  }
}


export function GuardarTotalSubtotal(Subtotal, Total, Precioenvio) {
  return (dispatch) => {
    dispatch(guardarTotal(Subtotal, Total, Precioenvio));
  };
}
export const guardarTotal = (Subtotal, Total, Precioenvio) => ({
  type: GUARDAR_TOTAL_SUBTOTAL,
  subtotal: Subtotal,
  total: Total,
  envio: Precioenvio
});
